body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background-color: #121212;
}

h1, h2, h3 {
  color: #ffffff;
}

/*Buttons styling*/
.button-style {
  border-radius: 12%;
  width: 100px;
  height:5vh;
  text-align:center;
  color: #ffffff;
  border: none;
  background-color: #388cd6;
}

.navbuttons {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}

.pad{
  padding-bottom: 3vh;
  text-align: center;
}

.NavBar {
  display: flex;
  height: 100vh;
  color: #ffffff;
  background-color: #2b2b2b; /* Dark background for contrast */
}

.left {
  position: fixed;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  background-color: #333333; /* Dark gray for the sidebar */
  padding: 0; /* Removed padding around the .left container */
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center all elements vertically */
  align-items: center; /* Center all elements horizontally */
  grid-gap: 10px;
  gap: 10px; /* Minimal space between child elements */
}

.right {
  margin-left: 25%;
  padding: 20px;
  width: 75%;
  overflow-y: auto;
}

#logo-img {
  width: 100%;
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px; /* Reduced bottom margin */
}

#contacts {
  margin: 10px 0; /* Reduced margin for consistent spacing */
}

#icons {
  max-width: 35px;
  max-height: 35px;
  margin: 0 5px; /* Reduced horizontal margin */
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

#icons:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); /* Slight zoom effect on hover */
}

#bio {
  font-size: 1.1rem;
  margin: 5px 0; /* Reduced vertical margin */
}

#colour {
  color: #9c9c9c;
}

#copyright {
  font-size: 0.9rem;
  color: #bbb;
}

.nav-buttons {
  margin-top: 15px; /* Reduced top margin */
}

.nav-buttons .pad {
  margin-bottom: 10px; /* Reduced bottom margin */
}

.button-style {
  background-color: #9c9c9c;
  border: none;
  color: white;
  padding: 8px 15px; /* Reduced padding for smaller buttons */
  font-size: 0.9rem; /* Slightly smaller font size */
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
}

.button-style:hover {
  background-color: #1c86ee; /* Slightly darker blue on hover */
  -webkit-transform: scale(1.05);
          transform: scale(1.05); /* Slight zoom effect on hover */
}

.button-style:focus {
  outline: none;
}

.left, .right {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .NavBar {
    flex-direction: column;
  }

  .left {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0; /* Removed padding for smaller screens */
  }

  .right {
    width: 100%;
    margin-left: 0;
  }

  #logo-img {
    width: 50%;
    margin-bottom: 5px; /* Reduced bottom margin */
  }

  #contacts {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Reduced bottom margin */
  }

  .nav-buttons .pad {
    width: 100%;
  }

  .button-style {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }
}

.project-box {
  display: flex;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-image {
  flex: 0 0 100px;
  text-align: center;
}

.project-display {
  max-width: 100px;
  border-radius: 10%;
}

.project-details {
  flex: 1 1;
  padding-left: 1rem;
}

.project-title {
  font-size: 1.5rem;
  color: #2a659c;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.project-description {
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #555;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.main-body {
  margin: 0 auto;
  max-width: 900px;
  color: #ffffff;
  text-align: left;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.main-content {
  font-size: 1.1rem;
  line-height: 1.6;
}

.main-header {
  color: #388cd6;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.main-text {
  margin: 5px 0 15px 15px;
  font-size: 1rem;
}

.highlight {
  color: #388cd6;
  font-weight: bold;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.skills-box {
  flex: 1 1;
  margin: 10px;
  min-width: 200px;
}

.skills-list {
  list-style-type: none;
  padding: 0;
}

.skills-list li {
  margin: 5px 0;
  font-size: 1rem;
}

.welcome-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    background-color: inherit; /* Parent background color */
    color: white; /* Text color */
    -webkit-animation: fadeInBackground 1.5s ease-in-out;
            animation: fadeInBackground 1.5s ease-in-out; /* Background animation */
    text-align: center; /* Proper text alignment */
  }
  
  .welcome-text {
    font-size: 3rem;
    font-weight: bold;
    -webkit-animation: textFadeIn 2s ease-in-out;
            animation: textFadeIn 2s ease-in-out; /* Smooth fade-in for text */
    margin-bottom: 20px; /* Space below the text */
  }
  
  .continue-button {
    background-color: #9c9c9c; /* Blue button */
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px; /* Space between text and icon */
    -webkit-animation: textFadeIn 2.5s ease-in-out;
            animation: textFadeIn 2.5s ease-in-out; /* Smooth fade-in for button */
    transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .continue-button:hover {
    background-color: #1c86ee; /* Slightly darker blue */
    -webkit-transform: scale(1.05);
            transform: scale(1.05); /* Slight zoom effect */
  }
  
  .arrow-icon {
    font-size: 1.5rem; /* Arrow size */
  }
  
  /* Keyframes for text and button fade effect */
  @-webkit-keyframes fadeInBackground {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeInBackground {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes textFadeIn {
    0% {
      opacity: 0;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  @keyframes textFadeIn {
    0% {
      opacity: 0;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
