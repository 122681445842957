.NavBar {
  display: flex;
  height: 100vh;
  color: #ffffff;
  background-color: #2b2b2b; /* Dark background for contrast */
}

.left {
  position: fixed;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  background-color: #333333; /* Dark gray for the sidebar */
  padding: 0; /* Removed padding around the .left container */
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center all elements vertically */
  align-items: center; /* Center all elements horizontally */
  gap: 10px; /* Minimal space between child elements */
}

.right {
  margin-left: 25%;
  padding: 20px;
  width: 75%;
  overflow-y: auto;
}

#logo-img {
  width: 100%;
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px; /* Reduced bottom margin */
}

#contacts {
  margin: 10px 0; /* Reduced margin for consistent spacing */
}

#icons {
  max-width: 35px;
  max-height: 35px;
  margin: 0 5px; /* Reduced horizontal margin */
  transition: transform 0.3s ease;
}

#icons:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

#bio {
  font-size: 1.1rem;
  margin: 5px 0; /* Reduced vertical margin */
}

#colour {
  color: #9c9c9c;
}

#copyright {
  font-size: 0.9rem;
  color: #bbb;
}

.nav-buttons {
  margin-top: 15px; /* Reduced top margin */
}

.nav-buttons .pad {
  margin-bottom: 10px; /* Reduced bottom margin */
}

.button-style {
  background-color: #9c9c9c;
  border: none;
  color: white;
  padding: 8px 15px; /* Reduced padding for smaller buttons */
  font-size: 0.9rem; /* Slightly smaller font size */
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-style:hover {
  background-color: #1c86ee; /* Slightly darker blue on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.button-style:focus {
  outline: none;
}

.left, .right {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .NavBar {
    flex-direction: column;
  }

  .left {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0; /* Removed padding for smaller screens */
  }

  .right {
    width: 100%;
    margin-left: 0;
  }

  #logo-img {
    width: 50%;
    margin-bottom: 5px; /* Reduced bottom margin */
  }

  #contacts {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Reduced bottom margin */
  }

  .nav-buttons .pad {
    width: 100%;
  }

  .button-style {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }
}
