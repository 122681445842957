.main-body {
  margin: 0 auto;
  max-width: 900px;
  color: #ffffff;
  text-align: left;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.main-content {
  font-size: 1.1rem;
  line-height: 1.6;
}

.main-header {
  color: #388cd6;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.main-text {
  margin: 5px 0 15px 15px;
  font-size: 1rem;
}

.highlight {
  color: #388cd6;
  font-weight: bold;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.skills-box {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.skills-list {
  list-style-type: none;
  padding: 0;
}

.skills-list li {
  margin: 5px 0;
  font-size: 1rem;
}
