html {
  background-color: #121212;
}

h1, h2, h3 {
  color: #ffffff;
}

/*Buttons styling*/
.button-style {
  border-radius: 12%;
  width: 100px;
  height:5vh;
  text-align:center;
  color: #ffffff;
  border: none;
  background-color: #388cd6;
}

.navbuttons {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}

.pad{
  padding-bottom: 3vh;
  text-align: center;
}
